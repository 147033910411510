<template>
  <!-- Container Card -->
  <b-card
    no-body
  >
  <div v-if="error">
    LinkedIn Auth error: {{error}}
  </div>
  <div v-else>
    Authenticate with LinkedIn... Please wait...
  </div>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardTitle, BCardText, BButton, BAvatar, BOverlay,
} from 'bootstrap-vue'
import { getAuth, signInWithCustomToken } from "firebase/auth";
import { getFirestore, doc, getDoc } from 'firebase/firestore';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardTitle, 
    BCardText,
    BButton,
    BAvatar,
    BOverlay,
  },
  data() {
    return {
      error: null,
    }
  },
  async mounted() {
    if(this.$route.query.error){
      this.error = this.$route.query.error
    }else if(this.$route.query.code){
      let code = this.$route.query.code
      let state = this.$route.query.state

       // Get the linkedin Data and login to hustleforge
      this.$http.get('https://us-central1-ideabox-335103.cloudfunctions.net/linkedinToken', { params: { code: code, state: state  } }).then(res => { 
        // console.log(res.data) 
        const token = res.data.token

        // Do you a login token
        if (res.data.token) {
          const auth = getAuth()
          //setPersistence(auth, browserLocalPersistence).then(() => {
            signInWithCustomToken(auth, token).then((userCredential) => { 
              const db = getFirestore()
              const username = userCredential.user.displayName

              // Does the user have a profile already
              getDoc(doc(db, "profiles", username)).then((profileRes) => { 
              
                // Build userData and store locally
                let profile = profileRes.data()
                profile.role = "admin" // Hardcode this for now
                profile.ability = []
                profile.ability.push({action: "manage", subject: "all"}) // Hardcode this for now
                localStorage.setItem('userData', JSON.stringify(profile))
                this.$ability.update(profile.ability) // TODO Change this later
                // console.log(profile) 
                window.close() 
              })
            })
          //})
        } else {
          console.error(data);
          document.body.innerText = 'Error in the token Function: ' + data.error;
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
</style>

